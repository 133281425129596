import warehouseServices from '@/services/warehouse.service'
import dictionaryServices from '@/services/dictionary.service'

export default {
  incomingOrderItems: {
    name: 'warehouse_inc_order_items',
    entities: [
      {
        name: 'ID',
        type: 'string',
        readonly: true
      },
      {
        name: 'Finished Product',
        type: 'dropdown',
        readonly: false,
        required: true,
        optionsService: dictionaryServices.fetchFinishedProductsBottling
      },
      {
        name: 'Bottle Size',
        type: 'dropdown',
        readonly: false,
        required: true,
        optionsService: dictionaryServices.fetchBottleSizes
      },
      {
        name: 'Bottles',
        type: 'number',
        readonly: false,
        required: true,
        defaultValue: null
      },
      {
        name: 'Bottles WH',
        type: 'number',
        readonly: true
      },
      {
        name: 'Bottles Diff',
        type: 'number',
        readonly: true
      },
      {
        name: 'Cases',
        type: 'number',
        readonly: true
      },
      {
        name: 'Cases WH',
        type: 'number',
        readonly: true
      },
      {
        name: 'Cases Diff',
        type: 'number',
        readonly: true
      },

      {
        name: 'Cost per Case',
        type: 'number',
        readonly: false,
        required: false,
        defaultValue: 0
      },
      {
        name: 'Organic Status',
        type: 'dropdown',
        readonly: false,
        required: true,
        optionsService: dictionaryServices.fetchOrganicStatuses,
        defaultValue: { id: 1, label: 'ORGANIC' }
      },

      {
        name: 'Line Cost',
        type: 'number',
        readonly: true
      },
      {
        name: 'Item Status',
        type: 'string',
        readonly: true
      }
    ],
    services: {
      fetchData: warehouseServices.fetchIncomingOrderItems
      //child table will send event to parent order and save method will be called there
      //saveRecord: warehouseServices.saveIncomingOrderItem
    },
    //actions: ["Create", "Delete"],
    actions: {
      Create: {
        validator: row => !(row['Invoice Number'] > 0)
      },
      Delete: {
        validator: row => !(row['Invoice Number'] > 0)
      }
    }
  }
}
