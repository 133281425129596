import { api } from '@/shared/api'

const warehouse = {
  async fetchProductNeedReport (payload) {
    return api.post('warehouse/product-need', payload)
  },
  async fetchProductNeedAccountReport (payload) {
    return api.post('warehouse/product-need-account', payload)
  },
  async fetchIncomingOrders (payload) {
    return api.post('warehouse/incoming-orders', payload)
  },
  async fetchIncomingOrder (id) {
    return api.get(`warehouse/incoming-order/${id}`, id)
  },
  async saveIncomingOrder (data) {
    console.log('saveIncomingOrder', JSON.stringify(data))
    let url = ''

    if (data['ID']) {
      url = `warehouse/incoming-order/${data['ID']}`
    } else {
      url = `warehouse/incoming-order`
    }

    return api.put(url, data)
  },
  async deleteIncomingOrder (id) {
    return api.delete(`warehouse/incoming-order/${id}`)
  },
  async saveIncomingOrderItem (data) {
    return api.put(`warehouse/incoming-order/item/${data['ID']}`, data)
  },
  async fetchIncomingOrderItems (payload) {
    return api.get(`warehouse/incoming-order/${payload.parentId}/items`)
  },
  async fetchPickListItems (payload) {
    return api.post(`warehouse/pick-list/items`, payload)
  },
  async fetchPickListBottlings (payload) {
    console.log('payload', payload)
    return api.post(`warehouse/pick-list/bottlings`, payload)
  },
  async createPicklistBottling (payload) {
    return api.put(`warehouse/pick-list/bottling`, payload)
  },
  async updatePicklistBottling (payload) {
    return api.put(`warehouse/pick-list/bottling/${payload['ID']}`, payload)
  },
  async deletePicklistBottling (payload) {
    return api.delete(`warehouse/pick-list/bottling/${payload['ID']}`)
  },

  async fetchPickLists (payload) {
    return api.post('warehouse/pick-lists', payload)
  },
  async fetchPickList (id) {
    return api.get(`warehouse/pick-list/${id}`, id)
  },
  async fetchAvailableBottlings (payload) {
    return api.post(`bottlings/available`, payload)
  },

  async savePickList (data) {
    let url = ''

    if (data['ID']) {
      url = `warehouse/pick-list/${data['ID']}`
    } else {
      url = `warehouse/pick-list`
    }
    return api.put(url, data)
  },
  async deletePickList (id) {
    return api.delete(`warehouse/pick-list/${id}`)
  },

  async deleteInventoryRecord () {},
  async fetchAvailableProducts (payload) {
    return api.post('warehouse/available-products', payload)
  },
  async fetchAvailableProductsTotals (payload) {
    return api.post('warehouse/available-products-totals', payload)
  },
  async fetchWarehouseStatuses () {
    return [
      { id: 5, name: 'Available bottles' },
      { id: 1, name: 'Available and used bottles' },
      { id: 2, name: 'All Errors' },
      { id: 4, name: 'Negative Errors' },
      { id: 3, name: 'Positive Errors' }
    ]
  },
  async fetchInvoicePayStatuses () {
    return [
      { id: 'OK', name: 'OK' },
      { id: 'OVERDUE', name: 'OVERDUE' }
    ]
  },
  async fetchInvoiceCheckStatuses () {
    return [
      { id: 'OK', name: 'OK' },
      { id: 'ERROR', name: 'ERROR' }
    ]
  },
  async fetchInvoices (payload) {
    let result = await api.post('warehouse/invoices', payload)

    return result
  },
  async fetchInvoice (id) {
    return api.get(`warehouse/invoice/${id}`, id)
  },
  async saveInvoice (data) {
    console.log('saveInvoice', data)
    let url = ''

    if (data['ID']) {
      url = `warehouse/invoice/${data['ID']}`
    } else {
      url = `warehouse/invoice`
    }
    return api.put(url, data)
  },
  async saveInvoiceItems () {
    return true
  },
  async deleteInvoice (id) {
    return api.delete(`warehouse/invoice/${id}`)
  },
  async fetchInvoiceItems (payload) {
    return api.get(`warehouse/invoice/${payload.parentId}/items`)
  }
}

export default warehouse
